/** @jsx jsx */
import { jsx } from 'theme-ui'

import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

// const renderers = {
//   code: ({ language, value }) => {
//     return (
//       <SyntaxHighlighter style={dark} language={language} children={value} />
//     )
//   },
// }

const Markdown = ({ as: Tag, className, children, source, ...props }) => {
  if (source) {
    source = source.replace(/\n/gi, '<br/>')
  }

  return (
    <Tag className={`markdown ${className}`} {...props}>
      {source && (
        <ReactMarkdown
          source={source}
          unwrapDisallowed={true}
          escapeHtml={false}
        />
      )}
      {children}
    </Tag>
  )
}

Markdown.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  source: PropTypes.string,
}

Markdown.defaultProps = {
  as: 'div',
  children: null,
  className: '',
  source: null,
}

export default Markdown
